.markdown-seo-text {
  margin-top: 12px;
  h2 {
    margin: 30px 0 35px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #424242;
    border-left: solid 4px #4f5256;
    padding: 0px 5px;
  }
}

.markdown-table {
  overflow-x: auto;
  table {
    padding: 0;
  }
  table tr {
    border-top: 1px solid #cccccc;
    background-color: white;
    margin: 0;
    padding: 0;
  }
  table tr th {
    font-weight: bold;
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
  }
  table tr td {
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px;
  }
  table tr th :first-child,
  table tr td :first-child {
    margin-top: 0;
  }
  table tr th :last-child,
  table tr td :last-child {
    margin-bottom: 0;
  }
}
